import { ActivatedRouteSnapshot } from '@angular/router';
import CONFIGURATION from '../configuration/configuration';
import {
  ID_M1CB_BRAVEN_PRODUCT_ID,
  ID_M1CB_COPPER_RAVEN_PRODUCT_ID,
  ID_M1CB_PRODUCT_ID,
  ID_M1CB_RED_RAVEN_PRODUCT_ID,
  ID_M1CB_SILVER_RAVEN_PRODUCT_ID,
  ID_M1CB_WHITE_RAVEN_PRODUCT_ID,
  MY_BOLT_GRAB_PRODUCT_ID,
  MY_BOLT_PRODUCT_ID,
  MY_ECOM_FINANCING_PRODUCT_ID,
  SG_BOLT_PRODUCT_ID,
} from '../constants/micro-loan.constants';
import { environment } from '../environments/environment';
import { Menu } from '../models/navigation.model';

function urlSegmentsToUrl(route: ActivatedRouteSnapshot): string {
  return route.url.map((segment) => segment.toString()).join('/');
}

function getLastRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let lastRoute = route;
  if (lastRoute.parent === null) {
    // Top down traversal to get last node in tree.
    while (lastRoute.firstChild !== null) {
      lastRoute = lastRoute.firstChild;
    }
  }
  return lastRoute;
}

/* Output /path/to/route/123/tab- */
function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot.map(urlSegmentsToUrl).join('/');
}

/* Output /path/to/route/:id/tab- */
function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  const targetRoute = getLastRoute(route);
  return targetRoute.pathFromRoot
    .map((activeRoute) => {
      if (activeRoute.routeConfig) {
        if (activeRoute.routeConfig.path) {
          return activeRoute.routeConfig.path;
        }
        // For segment matcher config, resolve to actual value.
        if (activeRoute.routeConfig.matcher) {
          return urlSegmentsToUrl(activeRoute);
        }
      }
      return null;
    })
    .join('/');
}

function generateNavigation(enableCollection: boolean, enableMasterData: boolean): Menu[] {
  return [
    {
      route: '/Home',
      icon: 'fa fa-desktop',
      translateKey: 'menu.home',
    },
    {
      icon: 'fa fa-database',
      translateKey: 'menu.master_data',
      hide: !enableMasterData && environment.masterCustomerData,
      children: [
        {
          route: environment.masterCustomerData.companiesUrl,
          translateKey: 'menu.companies',
          external: true,
          validateRoute: false,
        },
        {
          route: environment.masterCustomerData.individualsUrl,
          translateKey: 'menu.individuals',
          external: true,
          validateRoute: false,
        },
      ],
    },
    {
      icon: 'fa fa-group',
      translateKey: 'menu.member',
      children: [
        {
          route: '/member/investors',
          translateKey: 'menu.investors',
          validateRoute: true,
        },
        {
          route: '/member/financial-advisor',
          translateKey: 'menu.financial-advisor',
          validateRoute: true,
          country: ['MY'],
        },
        {
          route: '/member/borrowers',
          translateKey: 'menu.borrowers',
          validateRoute: true,
        },
        {
          route: '/member/partners',
          translateKey: 'menu.partners',
          validateRoute: true,
        },
        {
          route: '/member/qualified-leads',
          translateKey: 'menu.qualified_leads',
          validateRoute: true,
        },
        {
          route: '/member/kyc',
          translateKey: CONFIGURATION.kycSubstitution?.KYC,
          validateRoute: true,
        },
        {
          route: '/member/application',
          translateKey: CONFIGURATION.kycSubstitution?.APPLICATION,
          validateRoute: true,
        },
        {
          validationRoutes: ['/member/staffs'],
          translateKey: 'menu.staff',
          validateRoute: true,
          children: [
            {
              route: '/member/staff/screens',
              translateKey: 'menu.screens',
            },
            {
              route: '/member/staff/roles',
              translateKey: 'menu.roles',
            },
            {
              route: '/member/staff/users',
              translateKey: 'menu.staff_list',
            },
          ],
        },
        {
          translateKey: 'menu.communication',
          validationRoutes: ['/communication/sms'],
          children: [
            {
              route: '/communication/sms',
              translateKey: 'menu.sms',
            },
          ],
          validateRoute: true,
        },
      ],
    },
    {
      icon: 'fa fa-diamond',
      translateKey: 'menu.trade_finance',
      validationRoutes: ['/facility/trade-finance'],
      validateRoute: true,
      children: [
        {
          translateKey: 'menu.facilities',
          validationRoutes: ['/facility/lifecycle'],
          validateRoute: true,
          children: [
            {
              route: '/facility/lifecycle',
              translateKey: 'menu.lifecycle',
            },
          ],
        },
        {
          translateKey: 'menu.loan_applications',
          validationRoutes: ['/facility/application'],
          validateRoute: true,
          children: [
            {
              route: '/facility/application/invoices',
              translateKey: 'menu.invoice_approval',
            },
            {
              route: '/facility/application/drawdown',
              translateKey: 'menu.drawdown_approval',
              country: ['MY', 'ID'],
            },
            {
              route: '/facility/application/request-financing-invoices',
              translateKey: 'menu.request_financing_invoices',
            },
            {
              route: '/facility/application/request-financing-drawdowns',
              translateKey: 'menu.request_financing_drawdowns',
              country: ['MY', 'ID'],
            },
            {
              route: '/facility/application/simulate-repayment',
              translateKey: 'menu.simulate_repayment',
            },
          ],
        },
        {
          translateKey: 'menu.credit_card',
          validationRoutes: ['/facility/credit-card'],
          validateRoute: true,
          children: [
            {
              route: '/facility/credit-card/kyc',
              translateKey: 'menu.credit_card_applications',
              validateRoute: true,
            },
            {
              route: '/facility/credit-lines',
              translateKey: 'menu.credit_lines',
              validateRoute: true,
            },
          ],
        },
        {
          translateKey: 'menu.repayment_claims',
          validationRoutes: ['/facility/repayments'],
          validateRoute: true,
        },
        {
          translateKey: 'menu.companies',
          validationRoutes: ['/facility/companies'],
          validateRoute: true,
          children: [
            {
              route: '/facility/companies',
              translateKey: 'menu.companies_list',
            },
            {
              route: '/facility/companies/users',
              translateKey: 'menu.users',
            },
          ],
        },
        {
          translateKey: 'menu.settings',
          validationRoutes: ['/facility/types'],
          validateRoute: true,
          children: [
            {
              route: '/facility/types',
              translateKey: 'menu.types',
            },
            {
              route: '/facility/partner/acquisition',
              translateKey: 'Partner Acquisition (Indomie)',
            },
            {
              route: '/facility/ratings/configuration',
              translateKey: 'Credit Rating Configuration',
              validateRoute: true,
            },
          ],
        },
        {
          translateKey: "What's New",
          validationRoutes: ['/facility/dashboard'],
          validateRoute: true,
          route: '/facility/dashboard',
        },
        {
          translateKey: 'Silkroad FPX',
          route: '/facility/silkroad-fpx',
          country: ['MY'],
          validateRoute: true,
        },
      ],
    },
    {
      icon: 'fa fa-bank',
      translateKey: 'menu.elevate',
      validationRoutes: ['/elevate'],
      validateRoute: true,
      children: [
        {
          translateKey: 'menu.business_accounts',
          route: '/elevate/business-accounts/lifecycle',
          validateRoute: true,
        },
      ],
    },
    {
      icon: 'fa fa-credit-card',
      translateKey: 'menu.loan',
      validationRoutes: ['/loan'],
      validateRoute: true,
      children: [
        {
          translateKey: 'menu.settings',
          children: [
            {
              translateKey: 'menu.groups',
              route: '/loan/groups',
              validateRoute: true,
            },
            {
              translateKey: 'menu.products',
              route: '/loan/products',
              validateRoute: true,
            },
          ],
        },
        {
          translateKey: 'menu.recourses',
          route: '/loan/recourses',
          validateRoute: true,
        },
        {
          translateKey: 'menu.lifecycle',
          route: '/loan/lifecycle',
          validateRoute: true,
        },
      ],
    },
    {
      icon: 'fa fa-exchange',
      translateKey: 'menu.investor-channeling',
      children: [
        {
          translateKey: 'Partners',
          route: '/investor-chan/partners',
          validateRoute: true,
          country: ['SG', 'ID'],
        },
        {
          translateKey: 'Lifecycle',
          children: [
            {
              translateKey: 'Application List',
              route: '/investor-chan/applications',
              validationRoutes: ['/investor-chan/applications'],
              validateRoute: true,
              country: ['SG', 'ID'],
            },
            {
              translateKey: 'Creation',
              route: '/investor-chan/applications/create',
              validationRoutes: ['/investor-chan/applications/create'],
              validateRoute: true,
              country: ['SG', 'ID'],
            },
            {
              translateKey: 'Approval On Behalf',
              route: '/investor-chan/channelling-on-behalf',
              validateRoute: true,
              country: ['SG', 'ID'],
            },
            {
              translateKey: 'Disbursements',
              route: '/investor-chan/disbursements',
              validateRoute: true,
              country: ['SG', 'ID'],
            },
          ],
        },
      ],
    },
    {
      icon: 'fa fa-file-text',
      translateKey: 'menu.collaterals',
      children: [
        {
          translateKey: 'Management',
          route: '/collaterals/management',
          validateRoute: true,
        },
      ],
    },
    {
      translateKey: 'menu.micro_loan',
      icon: 'fa fa-mobile fa-lg',
      validateRoute: true,
      validationRoutes: [
        '/microloan/m1cb',
        '/microloan/boltsg',
        '/microloan/boltmy-grab',
        '/microloan/boltmy',
        '/microloan/utilities',
      ],
      children: [
        {
          route: '/microloan/' + ID_M1CB_PRODUCT_ID,
          screenRoute: '/microloan/m1cb',
          translateKey: 'M1CB',
          validateRoute: true,
        },
        {
          route: '/microloan/' + ID_M1CB_RED_RAVEN_PRODUCT_ID,
          screenRoute: '/microloan/redraven',
          translateKey: 'M1CB Red Raven',
          validateRoute: true,
        },
        {
          route: '/microloan/' + ID_M1CB_SILVER_RAVEN_PRODUCT_ID,
          screenRoute: '/microloan/silverraven',
          translateKey: 'M1CB Silver Raven',
          validateRoute: true,
        },
        {
          route: '/microloan/' + ID_M1CB_COPPER_RAVEN_PRODUCT_ID,
          screenRoute: '/microloan/copperraven',
          translateKey: 'M1CB Copper Raven',
          validateRoute: true,
        },
        {
          route: '/microloan/' + ID_M1CB_WHITE_RAVEN_PRODUCT_ID,
          screenRoute: '/microloan/whiteraven',
          translateKey: 'M1CB White Raven',
          validateRoute: true,
        },
        {
          route: '/microloan/' + SG_BOLT_PRODUCT_ID,
          screenRoute: '/microloan/boltsg',
          translateKey: 'Bolt SG',
          validateRoute: true,
        },
        {
          route: '/microloan/' + MY_BOLT_PRODUCT_ID,
          screenRoute: '/microloan/boltmy',
          translateKey: 'Bolt MY',
          validateRoute: true,
        },
        {
          route: '/microloan/' + MY_BOLT_GRAB_PRODUCT_ID,
          screenRoute: '/microloan/boltmy-grab',
          translateKey: 'Bolt MY Grab',
          validateRoute: true,
        },
        {
          route: '/microloan/' + environment.productIds.boltMyCgcd,
          screenRoute: '/microloan/my-ecom',
          translateKey: 'BOLT MY (CGCD)',
          validateRoute: true,
        },
        {
          route: '/microloan/' + MY_ECOM_FINANCING_PRODUCT_ID,
          screenRoute: '/microloan/my-ecom',
          translateKey: 'Ecommerce Financing',
          validateRoute: true,
        },
        {
          route: '/microloan/' + environment.productIds.islamicMicrofinancingMY,
          screenRoute: '/microloan/my-ecom',
          translateKey: 'Islamic Microfinancing',
          validateRoute: true,
        },
        {
          route: '/microloan/' + environment.productIds.islamicEcommerceMY,
          screenRoute: '/microloan/my-ecom',
          translateKey: 'Islamic Ecom Financing',
          validateRoute: true,
        },
        {
          translateKey: 'menu.utilities',
          children: [
            {
              route: '/microloan/utilities/questionnaire',
              translateKey: 'menu.questionnaire',
              validateRoute: true,
            },
          ],
        },
      ],
    },
    {
      translateKey: 'menu.finance',
      icon: 'fa fa-money',
      validateRoute: true,
      validationRoutes: [
        '/finance/deposits',
        '/finance/withdrawals',
        '/finance/disbursals',
        '/finance/instalments',
        '/finance/bank/transactions',
        '/finance/bank/histories',
      ],
      children: [
        {
          route: '/finance/deposits',
          translateKey: 'menu.deposits',
          validateRoute: true,
        },
        {
          route: '/finance/withdrawals',
          translateKey: 'menu.withdrawals',
          validateRoute: true,
        },
        {
          route: '/finance/disbursals',
          translateKey: 'menu.disbursals',
          validateRoute: true,
        },
        {
          route: '/finance/transfer',
          translateKey: 'menu.transfer',
          validateRoute: true,
        },
        {
          translateKey: 'menu.dda',
          children: [
            {
              route: '/finance/dda/past',
              translateKey: 'menu.past',
              validationRoutes: ['/finance/dda/past'],
              validateRoute: true,
              country: ['SG'],
            },
            {
              route: '/finance/dda/upcoming',
              translateKey: 'menu.upcoming',
              validationRoutes: ['/finance/dda/upcoming'],
              validateRoute: true,
              country: ['SG'],
            },
          ],
        },
        {
          route: '/finance/instalments',
          translateKey: 'menu.instalments',
          validateRoute: true,
        },
        {
          route: '/finance/bank/transactions',
          translateKey: 'menu.bank_transactions',
          validateRoute: true,
        },
        {
          route: '/finance/bank/histories',
          translateKey: 'menu.bank_histories',
          validateRoute: true,
        },
        {
          route: '/finance/batch-upload/withdrawals',
          translateKey: 'menu.batch_injection',
          validateRoute: true,
        },
      ],
    },
    {
      translateKey: 'menu.utilities',
      icon: 'fa fa-wrench',
      validateRoute: true,
      validationRoutes: [
        '/utilities/scheduler',
        '/utilities/rdn',
        '/utilities/sbn',
        '/utilities/elo-training',
        '/utilities/elo-training-results',
        '/utilities/notification-management',
      ],
      children: [
        {
          translateKey: 'menu.scheduler',
          route: '/utilities/scheduler',
          validateRoute: true,
        },
        {
          translateKey: 'RDN',
          route: '/utilities/rdn',
          validateRoute: true,
        },
        {
          translateKey: 'SBN',
          route: '/utilities/sbn',
          validateRoute: true,
        },
        {
          translateKey: 'ELO Training Pages',
          route: '/utilities/elo-training',
          validateRoute: true,
        },
        {
          translateKey: 'ELO Training Results',
          route: '/utilities/elo-training-results',
          validateRoute: true,
        },
        {
          translateKey: 'Template Maintenance',
          route: '/utilities/esign-templates',
          validateRoute: true,
        },
        {
          translateKey: 'Notification Management',
          route: '/utilities/notification-management',
          validateRoute: true,
        },
      ],
    },
    // removed route for menu.dispatch
    {
      icon: 'fa fa-wrench',
      translateKey: 'menu.partnership',
      route: '/partnership',
      validateRoute: true,
    },
  ];
}

export { getResolvedUrl, getConfiguredUrl, generateNavigation };
