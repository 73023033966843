import { environment } from '../../environments/environment';

export const LOAN_ADDRESS = environment.Loan_EndPoint + '/v2';

export const ADDRESS_MICROLOAN_INTERNAL = environment.MicroLoan_Internal_Endpoint;

export const ADDRESS_COLLATERAL = environment.Collateral_Endpoint;
export const ADDRESS_RECOURSE = environment.Loan_EndPoint + '/v2' + '/recourse';
export const ADDRESS_LOAN_RECOURSE = environment.Loan_EndPoint + '/v2' + '/loan_recourse';
export const ADDRESS_LOAN_GROUP = environment.Loan_EndPoint + '/v2' + '/groups';
export const ADDRESS_REPAYMENT_MODEL = environment.Loan_EndPoint + '/v2' + '/repayment_model';
export const ADDRESS_PRODUCT = environment.Loan_EndPoint + '/v2' + '/products';
export const ADDRESS_LOAN = environment.Loan_EndPoint + '/v2' + '/loans';
export const ADDRESS_LOAN_STAGES = environment.Loan_EndPoint + '/v2' + '/stages';
export const ADDRESS_DMS_DOC = environment.Loan_EndPoint + '/v2' + '/product';
export const ADDRESS_LOAN_SCF = environment.Loan_EndPoint + '/v2' + '/scf';

export const ADDRESS_DOCGEN = environment.DocGen_EndPoint;

export const ADDRESS_LOOKUP_LOAN_STATUS = environment.Loan_EndPoint + '/v2' + '/lookup_loan_status';
export const ADDRESS_LOAN_PURPOSE = environment.Loan_EndPoint + '/v2' + '/purposes';
export const ADDRESS_LOAN_RECOURSE_TYPE = environment.Loan_EndPoint + '/v2' + '/recourse_type';

export const ADDRESS_DOC_GENERATION = environment.MicroLoan_Docs_Gen_Endpoint + '/v1/';
export const ADDRESS_DOC_GENERATION_V2 = environment.MicroLoan_Docs_Gen_Endpoint + '/v2/';

export const ADDRESS_DMS = environment.Document_EndPoint_V3;

export const ADDRESS_TEMPLATE_OFFER = environment.Loan_EndPoint + '/v2' + '/template_offer';
export const ADDRESS_TEMPLATE_FACTSHEET = environment.Loan_EndPoint + '/v2' + '/template_factsheet';

export const ADDRESS_MEMBER = environment.Member_EndPoint + '/members';

// export const ADDRESS_SCORESHEET = 'http://54.169.218.239/scoresheet';
export const ADDRESS_SCORESHEET = environment.Loan_EndPoint + '/v2' + '/scoresheet';
export const CTOS = environment.CTOS_EndPoint;

export const ADDRESS_NOTES = environment.User_Endpoint + '/ns';
export const ADDRESS_ASSIGNMENT = environment.User_Endpoint + '/ls';

export const ADDRESS_FINANCE = environment.Finance_Endpoint;

export const ADDRESS_UNDERWRITING = environment.Underwriting_Endpoint;
export const ADDRESS_FACILITY = environment.Facility_Endpoint;
export const ADDRESS_FACILITY_ASSIGNMENT = environment.Facility_Endpoint + '/v2/assignments';
export const ADDRESS_FACILITY_DOC = environment.Facility_Endpoint + '/v2/docs';
export const ADDRESS_FACILITY_DOC_TYPES = environment.Facility_Endpoint + '/doctypes';

export const ADDRESS_INVESTOR_CHANNELLING = environment.Investor_Channelling_Endpoint;

export const ADDRESS_PARTNERSHIP = environment.Partnership_Endpoint;
export const ADDRESS_PARTNERSHIP_DOC = environment.Partnership_Endpoint + '/docs/internal/url';
export const INVOICE = 'invoice';
export const DEALER = 'dealer';

export const ADDRESS_PAYLATER = environment.Paylater_EndPoint;
export const LOAN_SERVICE_ADDRESS = environment.Loan_EndPoint + '/v2';

export const ADDRESS_BUSINESS_ACCOUNT = environment.Business_Account_Endpoint;

export interface ResponseMessage {
  cp: number;
  data: Array<any>;
  status: string;
  total: number;
}
export interface ResponseMessage_2 {
  cp: number;
  data: any;
  status: string;
  total: number;
}

export const CONSTANTS = {
  MEMBER_TYPES: {
    FINANCIAL_ADVISORS: 6,
  },
  MEMBER_TYPES_CODE: {
    FINANCIAL_ADVISORS: 'FA',
  },
};

export const PRODUCT_IDS = {
  SG_BOLT: 11,
  SG_BOLT_GRI: 80,
  SG_SECURED: 23,
  SG_SECURED_GRI: 97,
  MY_BOLT: 25,
  MY_SETTLEMENT_FINANCING: 98,
  MY_GUARANTEED_MICRO_FINANCING: 110,
  MY_GSF: 111,
  MY_ECOM_FINANCING: 57,
  ID_ECOMMERCE_BTL: 75,
  ID_PINJAMAN_TERPROTEKSI: 118,
};

export enum onfidoEkycStatus {
  processing = 'processing',
  clear = 'clear',
  consider = 'consider',
}

export enum onfidoEkycDecision {
  approved = 'approved',
  rejected = 'rejected',
}

// Company Categories
export const companyCategories = ['Technology', 'Non-Technology'];
