import { OptionValue } from '../controls/classes/option-value';

export const LatePeriod: OptionValue[] = [
  { value: 0, text: 'One Time' },
  { value: 1, text: 'Daily' },
  { value: 7, text: 'Weekly' },
  { value: 30, text: 'Monthly' },
  { value: 360, text: 'Yearly' },
];

export const DefaultLateInterest = {
  SG: 0.1,
  MY: 0.1,
  ID: 0.2,
  ZI: 0.2,
};

export enum FacilityStatus {
  ORI_INCOMPLETE = 'ORI-INCOMPLETE',
  ORI_NEW = 'ORI-NEW',
  ORI_PROCESS = 'ORI-PROCESS',
  ORI_DOCS_PENDING = 'ORI-DOCS-PENDING',
  ORI_DOCS_COMPLETE = 'ORI-DOCS-COMPLETE',
  ORI_COMPLETE = 'ORI-COMPLETE',
  ORI_REJECT = 'ORI-REJECT',
  UND_NEW = 'UND-NEW',
  UND_PROCESS = 'UND-PROCESS',
  UND_VERIF_CALL = 'UND-VERIF-CALL',
  UND_DOCS_PENDING = 'UND-DOCS-PENDING',
  UND_DOCS_COMPLETE = 'UND-DOCS-COMPLETE',
  UND_PROCESS_PENDING = 'UND-PROCESS-PENDING',
  UND_PROCESS_SITEVISIT = 'UND-PROCESS-SITEVISIT',
  UND_PROCESS_POSTSITE = 'UND-PROCESS-POSTSITE',
  UND_PROCESS_MEMO = 'UND-PROCESS-MEMO',
  UND_APPROVE = 'UND-APPROVE',
  UND_REJECT = 'UND-REJECT',
  UND_REJECT_DEFER = 'UND-REJECT-DEFER',
  PRE_OFFER = 'PRE-OFFER',
  OFFER_SENT = 'OFFER-SENT',
  BRW_ACCEPT = 'BRW-ACCEPT',
  BRW_REJECT = 'BRW-REJECT',
  LIVE = 'LIVE',
  TO_BE_RENEWED = 'TO-BE-RENEWED',
  EXPIRED = 'EXPIRED',
}

export const FACILITY_COLUMN_NAME = {
  CODE: 'code',
  STATUS: 'status',
  COUNTRY_CODE: 'countryCode',
  VERSION: 'version',
  FACILITY_VERSION_DATE: 'facilityVersionDate',
  MASTER_FACILITY_ID: 'masterFacilityId',
  FACILITY_TYPE_ID: 'facilityTypeId',
  LIMIT: 'limit',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  IS_BORROWER: 'isBorrower',
  IS_EMPTY: 'isEmpty',
  IS_MASTER: 'isMaster',
  MAX_LOAN_TO_VALUE: 'maxLoanToValue',
  DISBURSEMENT_REQUEST_PERIOD: 'disbursementRequestPeriod',
  IS_ANNUAL_FEE_PERCENTAGE: 'isAnnualFeePercentage',
  ANNUAL_FEE_PERCENTAGE: 'annualFeePercentage',
  ADMISSION_FEE: 'admissionFee',
  ADMIN_FEE: 'adminFee',
  ADMIN_FEE_TYPE: 'adminFeeType',
  ADMIN_FEE_FIXED_AMOUNT: 'adminFeeFixedAmount',
  FACILITY_FEE: 'facilityFee',
  FACILITY_FEE_TYPE: 'facilityFeeType',
  BORROWER_COMPANY_NAME: 'borrowerCompanyName',
  DISCOUNTING_FEE: 'discountingFee',
  DISCOUNTING_FEE_TYPE: 'discountingFeeType',
  INTEREST_RATE: 'interestRate',
  INTEREST_RATE_TYPE: 'interestRateType',
  IS_LATE_FEE_PERCENTAGE: 'isLateFeePercentage',
  LATE_FEE_PERCENTAGE: 'lateFeePercentage',
  LATE_FEE: 'lateFee',
  LATE_FEE_TYPE: 'lateFeeType',
  LATE_INTEREST_FEE: 'lateInterestFee',
  LATE_INTEREST_FEE_TYPE: 'lateInterestFeeType',
  MINIMUM_DAY_TO_DISBURSE: 'minimumDayToDisburse',
  MAXIMUM_DAY_TO_DISBURSE: 'maximumDayToDisburse',
  MAXIMUM_TENOR_LENGTH: 'maximumTenorLength',
  INCREMENT_DAY: 'incrementDay',
  LATE_INCREMENT_DAY: 'lateIncrementDay',
  ALLOW_CREATE_INVOICE: 'allowCreateInvoice',
  ALLOW_APPROVE_INVOICE: 'allowApproveInvoice',
  DISBURSE_ON_DUE_DATE: 'disburseOnDueDate',
  AUTO_APPROVE: 'autoApprove',
  IS_ACTIVE: 'isActive',
  AUTO_APPROVE_TIME: 'autoApproveTime',
  DUE_DAY_PER_MONTH: 'dueDayPerMonth',
  CONTRACT_DATE: 'contractDate',
  FLAG_EXCLUDE_IN_QUERIES: 'flagExcludeInQueries',
  MEMBER_ID: 'memberId',
  MEMBER_NAME: 'memberName',
  PRODUCT_ID: 'productId',
  DEFAULT_LOAN_STATUS: 'defaultLoanStatus',
  DEFAULT_LOAN_STAGE_ID: 'defaultLoanStageId',
  DEFAULT_LOAN_STATUS_ID: 'defaultLoanStatusId',
  PARTNER_INFORMED_AT: 'partnerInformedAt',
  CREATED_AT: 'createdAt',
  CREATED_BY: 'createdBy',
  UPDATED_AT: 'updatedAt',
  UPDATED_BY: 'updatedBy',
  DELETED_AT: 'deletedAt',
  DELETED_BY: 'deletedBy',
  FACILITY_CATEGORY: 'facilityCategory',
  MINIMUM_AMOUNT_DUE: 'minimumAmountDue',
  DSCR: 'dscr',
  UPLIMIT: 'uplimit',
  UPLIMIT_PERCENTAGE: 'uplimitPercentage',
  PENALTY_FEE: 'penaltyFee',
  PENALTY_FEE_DAYS: 'penaltyFeeDays',
  PENALTY_INTERVAL_DAYS: 'penaltyIntervalDays',
  PENALTY_DOCUMENT_TYPES: 'penaltyDocumentTypes',
  CHANNEL: 'channel',
};

export enum COUNTRY_CODES {
  SG = 'SG',
  MY = 'MY',
  ID = 'ID',
  ZI = 'ZI',
  TH = 'TH',
  ZU = 'ZU',
}

export enum FACILITY_TYPE {
  MASTER = 'master',
  SUB = 'sub',
}

export enum PRODUCT_CODES {
  VC = 'VC',
  VO = 'VO',
  BP = 'BP',
}

export enum FACILITY_PRODUCT_TYPE {
  AP = 'AP',
  BO = 'BO',
  BP = 'BP',
  DF = 'DF',
  FA = 'FA',
  IF = 'IF',
  MCL = 'MCL',
  ML = 'ML',
  RL = 'RL',
  SF = 'SF',
  VC = 'VC',
  VO = 'VO',
  CC = 'CC',
}

export const FACILITY_GRADE_TYPE = [
  'AA',
  'A',
  'BB',
  'B',
  'CC',
  'C',
  'DD',
  'D',
  'EE',
  'E',
  'FF',
  'F',
];

export const ORIGINATION_FEE_CONDITION = {
  originationFeeOnRepayment: 'ORIGINATION_FEE_ON_REPAYMENT',
  originationFeeOnDisbursement: 'ORIGINATION_FEE_ON_DISBURSEMENT',
  zeroOriginationFee: 'ZERO_ORIGINATION_FEE',
};

export const INTEREST_FEE_CONDITION = {
  interestFeeOnDisbursement: 'INTEREST_ON_DISBURSEMENT',
  interestFeeOnRepayment: 'INTEREST_ON_REPAYMENT',
  zeroInterestFee: 'ZERO_INTEREST',
};

export const borneBy = {
  borrower: 'BORROWER',
  partner: 'PARTNER',
};

export enum FACILITY_INSTALLMENT_TYPE {
  monthly = 30,
  yearly = 360,
  daily = 1,
}

export enum FACILITY_TYPES {
  ML = 'ML',
}

export const TENOR_TYPE = {
  PER_ANNUM: 360,
  FLAT: 100,
  PER_MONTH: 30,
};

export const USER_TYPES = {
  SCFS: 'SCFS',
  CREDIT_CARD_MAIN: 'CREDIT-CARD-MAIN',
  CREDIT_CARD_ADD_ON: 'CREDIT-CARD-ADD-ON',
  BUSINESS_ACCOUNT_MAIN: 'BUSINESS-ACCOUNT-MAIN',
};

export const FACILITY_LOAN_FEE_TYPE_MAPPING = {
  PER_ANNUM: 360,
  PER_MONTH: 30,
  PER_WEEK: 7,
  PER_DAY: 1,
  FLAT: 100,
};

export interface FacilitySimulateRepayment {
  id: number;
  code?: string;
  osPrincipal: number;
  osFacility: number;
  osInterest: number;
  osLateFee: number;
  osLateInterestFee: number;
  gstAmount?: number;
  osPenaltyFee?: number;
  total: number;
}
